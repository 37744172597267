import React, { Component } from "react";

class NavLogo extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
    };
  }

  render() {
    return (
      <div className="header-branding d-flex justify-content-center align-items-center py-3">
        <img src={"/org/"+ window.orgUUID + "/logo-small"} height="44" />
      </div>
    );
  }
}

export default NavLogo;