import React from 'react';
import RecordThumbnail from '../assets/images/record-thumbnail.png';
import InviteThumbnail from '../assets/images/invite-thumbnail.png';
import DoneThumbnail from '../assets/images/done-thumbnail.png';
import { Link } from 'react-router-dom';
import { getBaseURL, isMobile } from "../helpers/URLHelper";
import Branding from "../brands/Branding";
import AutoPlayVideo from './AutoPlayVideo';

export default (props) => {
	return (
        <>
            {!!Branding.getHowItWorksVideo() && 
                <>
                    <h2 className="headline d-flex align-items-center me-2 me-md-4 mb-4" >
                        <span>{Branding.getHowItWorksVideoTitle()}</span>
                    </h2>
                    
                    <div className="row px-md-4"> 
                        <div className="step-wrapper col-md-12 px-0 px-md-4 mb-3">
                            {Branding.getHowItWorksVideo()}
                        </div>
                    </div>
                </>
            }


            <div className="how-it-works-wrapper row px-md-4">
                <h2 className="headline d-flex align-items-center me-2 me-md-4 my-3">
                    <span>{Branding.getStepsTitle(props)}</span>
                </h2>
                <div className="step-wrapper col-md-4">
                    {Branding.getStepOne(props)}
                </div>
                <div className="step-wrapper col-md-4">
                    {Branding.getStepTwo(props)}
                </div>
                <div className="step-wrapper col-md-4">
                    {Branding.getStepThree(props)}
                </div>
            </div>
        </>
	);
}
