"use client"
import StepsRecord from "../assets/branding/blank/steps-record.jpg";
import StepsInvite from "../assets/branding/blank/steps-invite.jpg";
import StepsShare from "../assets/branding/blank/steps-share.jpg";
import { getBaseURL } from "../helpers/URLHelper";
import { Link } from "react-router-dom";
import AutoPlayVideo from '../components/AutoPlayVideo';
import ShareButton from "../components/ShareButton";



class Brand {


	//
	//	SETUP PAGE
	//
	getSetupPageType = ()=>{
		return 'steps';
	}	
	getSetupImg = ()=>{
		return null;
	}
	getSetupText = ()=>{
		return "Creating your Video is easy, and simple. Click “Begin Customizing”, or read below to see how it all works.";
	}
	getSetupBtnText = ()=>{
		return "Begin Customizing";
	}

	//
	//	INVITE PAGE
	//
	getInviteHeadline = ()=>{
		return 'Is this from a Group?';
	}	
	getInviteBody = ()=>{
		return 'Make it a group surprise by collecting photos and videos from others. Edit the message below, and remember to let them know when it’s due.';
	}	
	getInviteSample = ( inviteSampleNoLink )=>{
		return inviteSampleNoLink;
	}	
	getInviteOthersBtnText = ()=>{
		return "Collect From Others";
	}	



	//
	//	View Invite PAGE
	//
	getViewInviteImg=()=>{
		return null;
	}
	getViewInviteBody=( serverMessage )=>{
		return serverMessage;
	}
	getViewInviteThankYouMessage = ()=>{
		return "Your photo or video was sent.";
	}
	isViewInviteRecordOnly = ()=>{
		return false;
	}
	getViewInviteRecordBtnText=()=>{
		return "Record a Video";
	}
	getViewInviteEmptyFormError=()=>{
		return "Please enter an email or a phone number.";
	}
	getViewInviteInfoRequest=()=>{
		return (<>
			Be among the first to know when the video is done.<br/>The final video will be sent to you by email or text.
		</>);
	}


	getFizzEmptyMessage = ()=>{
		return (
			<div className="row" >
				<div className="col-lg-8 offset-lg-2" >
					<h3 className="text-center mt-sm-4 pt-lg-4">
						When someone submits a video it&nbsp;will&nbsp;show&nbsp;here.
					</h3>
					<h5 className="text-center">
						This is also where you will see the photos and videos you&nbsp;upload. There’s no limit and you can come back to add&nbsp;more&nbsp;later.
					</h5>
	            </div>
			</div>
		);
	}

	getRecordType = ()=>{
		return 'default';
	}




	getQRGenCenterImage = () => {
		return null;
	}

	getSharedFizzContentTop = (props) => {
		return (
			<p style={{textAlign:'center',fontSize:'26px', marginTop:'16px'}}>{props.title}</p>
		);
	}
	
	getSharedFizzContentBottom = (props) => {
		return null
	}

	getViewThankYouBottom = (props) => {
		return null
	}

	getFizzShareOptions = ( props )=>{
		return(
			<div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2" >
				<ShareButton 
					shareUrl={props.shareUrl}
					fizzId={props.fizzId}
					showDownload={ props.showDownload }
					showGroupShare={ props.showGroupShare }
				/>
			</div>
		)
	}

	

	getHowItWorksVideoTitle = (props) => {
		return "How it works";
	}
	getHowItWorksVideo = (props) => {
		return (
			<AutoPlayVideo 
				poster="https://vfair.s3.us-west-2.amazonaws.com/pub/vf-how-it-works-2023.jpg"
				video="https://vfair.s3.us-west-2.amazonaws.com/pub/vf-how-it-works-2023.mp4"
			/>
		);
	}


	getStepsTitle = (props) => {
		return "It's So Easy";
	}
	getStepOne = ( {isInvite,themeSlug} ) => {
		return this.getStep({
			img: StepsRecord,
			title: "Record a Video",
			msg: "Write a personal message, select music, and continue to edit until your gift has been received!",
		});
	}
	getStepTwo = ( props ) => {
		return this.getStep({
			img: StepsInvite,
			title: "Invite Friends to Contribute",
			msg: "Send invites by text or email. Friends and family can add their own photos and videos to the project!",
		});
	}

	getStepThree = ( props ) => {
		return this.getStep({
			img: StepsShare,
			title: "You're Done!",
			msg: "When the recipient receives their gift, they'll scan a QR code or receive a link to their video.",
		});
	}
	getStep = (props)=>{
		return (
			<>
				<div className="step-thumbnail-wrapper">
					<img src={props.img} />
					<div className="info-strip">
						{props.title}
					</div>
				</div>
				<p className="mt-3 px-4 px-md-0">{props.msg}</p>
			</>
		);
	}
	

	getPreviewContentUnderVideo = (props) => {
		return (
	        <div className="row">
	            <div className="col-lg-10 offset-lg-1" >
					<div className="mt-3">
						<Link to={ getBaseURL( "/edit/" + props.fizzId ) } className="btn btn-outline-secondary btn-block btn-lg-inline-block px-5">
						Back To Edit
						</Link>
	              	</div>
	          	</div>
        	</div>
		);
	}

}


export default Brand