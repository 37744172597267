

export const trackFBEvent = ( eventName, data )=>{
    
    try{
        if( typeof(fbq)!=='undefined' ) {
            fbq('trackCustom', eventName,  data||{} );
        }
    } catch( e ){
        console.log("FB tracking failed:",e);
    }

}