import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/style.css";
import "./assets/css/buttons.css";
import "./assets/css/upload-list.css";
import "./assets/css/uploader.css";
import "./assets/css/notify.css";
import "./assets/css/preview.css";
import "./assets/css/invite-others.css";
import "./assets/css/footer.css";

import App from "./App";

 
var hostDomainProd = 'air.videofizz.com';
if( window && window.location ) {
  if( window.location.host == hostDomainProd ){
    // Allow logs right now
    // console.log = () => {}
    // console.error = () => {}
    // console.debug = () => {}
  }
}


ReactDOM.render(
  <React.StrictMode>
    <App orgUUID={window.orgUUID } orgKey={window.orgKey}/>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
