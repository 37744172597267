class TrackingHelper {

  trackPage(){

  }

  trackPreview(){
  }

}

export default new TrackingHelper()
