import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MainContent from "../components/MainContent";
import TrackingHelper from "../helpers/TrackingHelper";
import { Link } from "react-router-dom";

export class NotFound extends Component {

  componentDidMount(){
  }
  
  render() {
    return(
      <div className="screen-wrap">
        <NavBar/>
        <MainContent className="container bg-margin">


          <div className="row">
            <div className="col-md-12">
                
              <h2 className="headline d-flex flex-reverse-column flex-md-row align-items-center text-end my-4">Page not found</h2>

              <h6 className="subheadline mb-3"></h6>

            </div>

          </div>



        </MainContent>
        <Footer hideMobile={true} />
      </div>
    )
  }

}

export default NotFound;