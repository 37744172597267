"use client"

import React, { useState, useEffect, useRef } from "react";
import Mute from "../assets/images/vf-icons/sound-white-mute.svg";
import '../assets/css/video-player-custom.css'
import '../assets/css/video-thumb.css'


export default function VideoPlayerCustom(props) {

    
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);

  var { poster } = props.options || {};

  const videoRef = useRef(null);
  
  const handlePlay = () => {
    setPlaying(!playing);
    if (playing) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };
  
  const handleMute = () => {
    setMuted(!muted);
    videoRef.current.muted = !muted;
  };
  
  const handleTimeUpdate = () => {
    if( playing != !videoRef.current.paused ) {
        setPlaying( !videoRef.current.paused )
        if( !videoRef.current.paused ) {
          if( props.onPlayStart ) {
            props.onPlayStart(videoRef.current);
          }
        }
    }
    setTime(videoRef.current.currentTime);
    setDuration(videoRef.current.duration);
  };
  
  const handleProgress = (e) => {
    videoRef.current.currentTime = (e.target.value/e.target.max * duration);
    setTime(videoRef.current.currentTime);
  };


  function convertStoMs(seconds) {
    let minutes = Math.floor(seconds / 60);
    let extraSeconds = (seconds % 60);
    extraSeconds = (Math.round(extraSeconds)+'').padStart(2, '0')
    return minutes+":"+extraSeconds;
  }

    

  let sliderVal = (time / duration) * 100;
  if( !duration ) {
    sliderVal = 0;
  }
  return (
    <div className="video-player">
        {muted &&
            <img className="d-none" src={Mute} style={{position:'absolute',top:'5px',right:'5px',width:"9%",maxWidth:"60px"}}/>
        }
        <div className={'box-480 '+(!playing?'video-thumb':'')} onClick={handlePlay} >
            { props.src &&
              <video className="box-480-item"
                  ref={videoRef}
                  onTimeUpdate={handleTimeUpdate}
                  onLoadedMetadata={handleTimeUpdate}
                  autoPlay={true}
                  // controls={false}
                  loop={false}
                  poster={poster}
                  webkit-playsinline="true"
                  playsInline
              >
                  <source src={props.src} type="video/mp4" />
              </video>
            }
        </div>
        {/*<button onClick={handleMute}>{muted ? 'Unmute' : 'Mute'}</button>*/}
        { props.controls!==false && 
          <div className="d-flex" >
            <input 
                type="range" 
                min="0" 
                max={duration*29.97}
                value={time*29.97}
                onChange={handleProgress}
            />
            <div className="time text-end" >{convertStoMs(time)} / {convertStoMs(duration)}</div>
          </div>
        }
    </div>
  );
}
