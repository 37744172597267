"use client"
import Brand from './Brand';
import BoozyBites from './BoozyBites';
import Moonpig from './Moonpig';
import TheKnot from './TheKnot';
import TheBump from './TheBump';


const getBranding = () => {

	// var window = window||{}; // Server Side Render Protection
	if (typeof window === 'undefined') {
		return new Brand();
	}

	switch( window.orgKey ) {

		case 'boozybites':
			return new BoozyBites();

		case 'moonpig':
			return new Moonpig();
		
		case 'theknot':
			return new TheKnot();
		
		case 'thebump':
			return new TheBump();

		default:
			return new Brand();

	}
	return null;
} 

export default getBranding()